import React, { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { IconArrowRight } from "@tabler/icons-react";
import dayjs from "dayjs";
import axios from "axios";

// Amplify
import { generateClient } from "aws-amplify/api";
import {
  createWaitlistUser,
  updateWaitlistUser,
} from "../graphql/customMutations";

import {
  searchWaitlistUsers,
  addContactToList,
} from "../graphql/customQueries";

// GraphQL Client
const client = generateClient({ authMode: "apiKey" });

export const HomePage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isSiteEnabled, setIsSiteEnabled] = useState(false);
  const [relationshipStatus, setRelationshipStatus] = useState("");
  const [cityState, setCityState] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // User GeoLocation Data
  const [userCity, setUserCity] = useState("");
  const [userIP, setUserIP] = useState("");
  const [userCoordinates, setUserCoordinates] = useState("");
  const [userInternetProvider, setUserInternetProvider] = useState("");
  const [userZipCode, setUserZipCode] = useState("");
  const [userState, setUserState] = useState("");
  const [userTimeZone, setUserTimeZone] = useState("");
  const [errorState, setErrorState] = useState(false);

  // User Device Info Data
  const [userDevice, setUserDevice] = useState("");
  const [userPlatform, setUserPlatform] = useState("");
  const [userAppName, setUserAppName] = useState("");
  const [userAppVersion, setUserAppVersion] = useState("");
  const [userScreenSize, setUserScreenSize] = useState("");

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setIsEmailValid(false);
      return;
    }
    setIsEmailValid(true);
  };

  useEffect(() => {
    getLocationByIP();
  }, []);

  const getUserDeviceInfo = () => {
    setUserDevice(navigator.userAgent);
    setUserPlatform(navigator.platform);
    setUserAppName(`${navigator.appCodeName} - ${navigator.appName}`);
    setUserAppVersion(navigator.appVersion);
    setUserScreenSize(`${window.innerWidth}x${window.innerHeight}`);
  };

  useEffect(() => {
    getUserDeviceInfo();
  }, []);

  const getLocationByIP = async () => {
    const locationbyIP = await axios.get("https://ipinfo.io");
    const { city, ip, loc, org, postal, region, timezone } = locationbyIP.data;
    setUserCity(city);
    setUserIP(ip);
    setUserCoordinates(loc);
    setUserInternetProvider(org);
    setUserZipCode(postal);
    setUserState(region);
    setUserTimeZone(timezone);
  };

  useEffect(() => {
    window.sessionStorage.setItem("rd-email", email);
    window.sessionStorage.setItem("rd-relationshipStatus", relationshipStatus);
    window.sessionStorage.setItem("rd-cityState", cityState);
  }, [email, relationshipStatus, cityState]);

  useEffect(() => {
    window.sessionStorage.removeItem("rd-email");
    const getIsSiteEnabled =
      window.sessionStorage.getItem("isReadySiteEnabled");
    const readyCurrentTime = window.sessionStorage.getItem("readyCurrentTime");
    const readyTimeDiff1 = dayjs();
    const readyTimeDiff2 = dayjs(readyCurrentTime);
    const minutes = readyTimeDiff1.diff(readyTimeDiff2, "minutes");
    if (
      !window.location.hostname.includes("dev.") &&
      !window.location.hostname.includes("localhost")
    ) {
      console.log("SE");
      setIsSiteEnabled(true);
      return;
    } else {
      if (getIsSiteEnabled === "true" && minutes < 30) {
        console.log("SE");
        setIsSiteEnabled(true);
        return;
      } else {
        console.log("SNE");
        setIsSiteEnabled(false);
        window.sessionStorage.removeItem("readyCurrentTime");
        window.sessionStorage.removeItem("isReadySiteEnabled");
      }
    }
  }, []);

  const addUserToSendgrid = async (email) => {
    await client.graphql({
      query: addContactToList,
      variables: {
        email,
      },
    });
  };

  const sendEmail = async (from, to, position) => {
    try {
      const addUserToContacts = await axios.post(
        "https://hyth32w4ia.execute-api.us-east-1.amazonaws.com/add-contact",
        {
          email,
          position,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Force-Preflight": "true"
          },
        }
      );

      console.log("addUserToContacts", addUserToContacts);
    } catch (error) {
      console.log("Error sending Email: ", error);
    }
  };

  // const addReferlistUser = async () => {
  //   try {
  //     const getUserEmail = window.sessionStorage.getItem("rd-email");

  //     const response = await axios.post(
  //       "https://referlist.co/external/addsignup",
  //       {
  //         email: getUserEmail,
  //         referralCode: searchparams.get("ref"),
  //         verify: true,
  //       },
  //       {
  //         headers: {
  //           "api-key": process.env.REACT_APP_REFERLIST_API_KEY,
  //         },
  //       }
  //     );
  //     return response;
  //   } catch (error) {
  //     console.log("addReferlistUser error", addReferlistUser);
  //     throw new Error("Error adding user to Referlist", error);
  //   }
  // };

  // const getReferlistUserPosition = async () => {
  //   try {
  //     const getUserEmail = window.sessionStorage.getItem("rd-email");
  //     const getPosition = await axios.post(
  //       "https://referlist.co/external/position",
  //       {
  //         email: getUserEmail,
  //       },
  //       {
  //         headers: {
  //           "api-key": process.env.REACT_APP_REFERLIST_API_KEY,
  //         },
  //       }
  //     );
  //     return getPosition;
  //   } catch (error) {
  //     console.log("getReferlistUserPosition error", addReferlistUser);
  //     throw new Error("Error adding user to Referlist", error);
  //   }
  // };

  const goToSignUpForm = async () => {
    try {
      setErrorState(false);
      setIsLoading(true);

      // const response = await addReferlistUser();

      const getUserEmail = window.sessionStorage.getItem("rd-email");
      const getRelationshipStatus = window.sessionStorage.getItem(
        "rd-relationshipStatus"
      );
      const getCityState = window.sessionStorage.getItem(
        "rd-cityState",
        cityState
      );

      // if (response.status === 200) {
      // const { referralCode } = response.data;
      // const getPosition = await getReferlistUserPosition();
      // const { position } = getPosition.data;
      // await sendEmail(
      //   "waitlist@readyplatform.co",
      //   getUserEmail,
      //   position
      // );

      const {
        data: {
          searchWaitlistUsers: { items },
        },
      } = await client.graphql({
        query: searchWaitlistUsers,
        variables: {
          limit: 1,
          filter: {
            email: {
              eq: getUserEmail,
            },
          },
        },
      });
      if (items.length === 0) {
        const userValues = {
          email: getUserEmail,
          relationshipStatus: getRelationshipStatus,
          cityState: getCityState,
          // referralCode,
          userCity,
          userIP,
          userCoordinates,
          userInternetProvider,
          userZipCode,
          userState,
          userTimeZone,
          // position,
          userDevice,
          userPlatform,
          userAppName,
          userAppVersion,
          userScreenSize,
        };
        const addWaitlistUser = await client.graphql({
          query: createWaitlistUser,
          variables: {
            input: userValues,
          },
        });
        const {
          data: {
            createWaitlistUser: { id },
          },
        } = addWaitlistUser;

        const { data } = await client.graphql({
          query: searchWaitlistUsers,
          variables: {
            limit: 1,
            sort: {
              field: "createdAt",
              direction: "desc",
            },
          },
        });
        const usersCount = data?.searchWaitlistUsers?.total || 0;
        const totalCount = usersCount + 43000;

        addUserToSendgrid(getUserEmail);
        await sendEmail("waitlist@readyplatform.co", getUserEmail, totalCount);

        navigate({
          pathname: "/sign-up-form",
          search: createSearchParams({
            email: getUserEmail,
            userId: id,
            // referralCode,
            // position,
          }).toString(),
        });
      } else {
        const existingUserValues = {
          id: items[0].id,
          relationshipStatus: getRelationshipStatus,
          cityState: getCityState,
          email: getUserEmail,
          // position,
        };

        await client.graphql({
          query: updateWaitlistUser,
          variables: {
            input: existingUserValues,
          },
        });

        navigate({
          pathname: "/sign-up-form",
          search: createSearchParams({
            email: getUserEmail,
            userId: items[0].id,
            // referralCode,
            // position,
          }).toString(),
        });
      }

      setIsLoading(false);
      //}
    } catch (error) {
      console.log("Add User Error", error);
      setErrorState(true);
      setIsLoading(false);
      throw new Error("Error goToSignUpForm", error);
    }
  };

  const submitOnEnter = (event) => {
    if (event.keyCode === 13 && isEmailValid) {
      goToSignUpForm();
    }
  };

  const submitPasswordOnEnter = (event) => {
    if (event.keyCode === 13) {
      validatePassword();
    }
  };

  const validatePassword = () => {
    if (password === "R3aD1") {
      const readyCurrentTime = dayjs().format("YYYY-MM-DD HH:m");
      window.sessionStorage.setItem("isReadySiteEnabled", true);
      window.sessionStorage.setItem("readyCurrentTime", readyCurrentTime);
      setIsSiteEnabled(true);
    }
  };

  return !isSiteEnabled ? (
    <div className="w-screen h-screen relative flex flex-row items-center justify-center">
      <input
        className="border-b border-solid border-black bg-transparent py-2 w-10/12 sm:w-[300px] mr-4 outline-none font-extralight placeholder-charcoal/40 rounded-none"
        type="password"
        value={!password ? "" : password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        onKeyDown={(e) => submitPasswordOnEnter(e)}
        placeholder="Enter password to view site"
      />
      <button
        className={`${
          password ? "bg-black" : "opacity-40 bg-transparent"
        }  border border-solid border-black rounded-full flex items-center justify-center text-white w-[50px] h-[50px] text-[18px] cursor-pointer`}
        disabled={!password}
        onClick={() => validatePassword()}
      >
        <IconArrowRight color={password ? "#FFFFFF" : "#000000"} stroke={1} />
      </button>
    </div>
  ) : (
    <div className="w-full h-full relative flex flex-col items-start justify-start min-h-[750px]">
      <div className="w-full w-full flex items-left justify-start flex-col md:pl-12 lg:pl-[100px] relative z-20">
        <img
          className="pt-[56px]"
          width={100}
          src={require("./../assets/images/ready-logo-charcoal.svg").default}
          alt="Ready Platform Logo"
        />
        <p className="mt-[65px] text-[30px] md:text-[36px] lg:text-[42px] text-left leading-9 md:leading-[45px] lg:leading-[50px] font-ff font-normal text-charcoal">
          Finding love,
          <br />
          and staying in love,
          <br />
          can be hard.
          <br />
          We're here to make it easier.
        </p>
        <p className="text-[19px] sm:text-[20px] text-left leading-6 mt-[24px] font-inter-el font-thin text-charcoal">
          Ready is a relationship platform that fosters growth as you date,
          <br />
          and as you keep your relationship strong.
        </p>
        <div className="flex flex-col items-center justify-start mt-[56px] w-full md:w-[600px]">
          <div className="w-full flex flex-col sm:flex-row justify-between">
            <select
              className={`border-b border-solid ${
                relationshipStatus !== ""
                  ? "border-black text-black"
                  : "border-black/40 text-black/40"
              } bg-transparent py-2 mt-4 w-full sm:w-6/12 sm:mr-2 outline-none font-extralight placeholder-charcoal/40 rounded-none`}
              type="text"
              value={relationshipStatus}
              onChange={(e) => {
                setRelationshipStatus(e.target.value);
              }}
            >
              <option value="">Relationship status</option>
              <option value="Single">Single</option>
              <option value="In a relationship">In a relationship</option>
              <option value="It's complicated">It's complicated</option>
              <option value="Prefer not to say">Prefer not to say</option>
            </select>
            <input
              className={`border-b border-solid ${
                cityState !== "" ? "border-black" : "border-black/40"
              } bg-transparent py-2 mt-4 w-full sm:w-6/12 sm:ml-2 outline-none font-extralight placeholder-charcoal/40 rounded-none`}
              type="text"
              value={cityState}
              onChange={(e) => {
                setCityState(e.target.value);
              }}
              placeholder="City, state"
              autoComplete="off"
            />
          </div>
          <input
            className={`border-b border-solid ${
              isEmailValid ? "border-black" : "border-black/40"
            } bg-transparent py-2 mt-4 w-full outline-none font-extralight placeholder-charcoal/40 rounded-none`}
            type="email"
            autoComplete="off"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail();
            }}
            onKeyDown={(e) => submitOnEnter(e)}
            placeholder="Join waitlist with email"
          />
          {isLoading ? (
            <button
              className={`opacity-40 bg-vanilla text-black border border-solid border-black rounded-full flex items-center justify-center w-full cursor-pointer mt-4 py-2 px-4`}
              disabled={
                !isEmailValid || relationshipStatus === "" || cityState === ""
              }
              onClick={() => goToSignUpForm()}
            >
              Processing...
            </button>
          ) : (
            <button
              className="bg-black text-white border border-solid border-black rounded-full flex items-center justify-center w-full cursor-pointer mt-4 py-2 px-4"
              onClick={() => goToSignUpForm()}
            >
              Join to get early access
            </button>
          )}
          {errorState && (
            <p className="text-red-500 w-full text-center py-2 font-bold">
              Please check the information provided and try again.
            </p>
          )}
        </div>
      </div>
      <section className="flex flex-col items-center justify-start w-full z-10 md:pl-12 lg:pl-[100px] text-charcoal mt-10">
        <div className="w-full flex items-center justify-start">
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </p>
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/terms-of-use")}
          >
            Terms of Service
          </p>
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/careers")}
          >
            Careers
          </p>
        </div>
        <p className="mr-1 w-full text-left pt-2">
          Questions or concerns? Reach out to us at{" "}
          <a href="mailto:waitlist@readyplatform.co">
            waitlist@readyplatform.co
          </a>
        </p>
      </section>
      <div className="absolute bottom-0 left-0 -mr-6 -ml-6 -mb-6 overflow-hidden w-screen flex items-center justify-center sm:justify-center lg:justify-end">
        <img
          className="w-[460px] sm:w-[550px] md:w-[640px] lg:w-[570px] xl:w-[600px] -mb-[320px] sm:-mb-[350px] md:-mb-[470px] lg:-mb-[410px] xl:-mb-[270px]"
          src={require("./../assets/images/ready-hands.png")}
          alt="Ready Couple"
        />
      </div>
    </div>
  );
};
